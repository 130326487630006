


body{
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    vertical-align: middle;
    line-height: 1.5;
    padding: 0%;
    margin: 0%;
    overflow-x: hidden;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a,span,h2,p,h3{
    font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    vertical-align: middle;
    line-height: 1.5;
}

/* Apply to the entire page */
* {
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 transparent;
  }
  
  /* For WebKit browsers (Chrome, Safari, newer versions of Edge) */
  *::-webkit-scrollbar {
    width: 8px; /* width for vertical scrollbar */
    height: 8px; /* height for horizontal scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
    border: 3px solid transparent; /* adds a bit of spacing around thumb */
    background-clip: content-box; /* clips the thumb for rounded edges */
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0; /* color on hover */
  }


  .slim-divider {
    border-bottom: 1px solid #c4c4c4;
    margin: 0.5rem 0;
  }