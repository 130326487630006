.import-data-section{
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
        margin-top: 6vh;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 3vh;
        color: #ffffff;
    }

    .import-data-button{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.5rem;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #ffffff;
        width: 20%;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover{
            background-color: #ffffff54;
        }   
    }

    .popupContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        color: #ffffff;

        .popupTitle{
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .popupDescription{
            font-size: 1rem;
            margin-bottom: 10px;
        }

        .popupInput{
            width: 50%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #000000;
            margin-bottom: 10px;
        }

        .popupButton{
            padding: 10px 20px;
            border-radius: 5px;
            background-color: #000000;
            color: #ffffff;
            cursor: pointer;
            transition: background-color 0.3s;
            &:hover{
                background-color: #91919154;
            }
        }
    }
}