.dropdown-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.button-open {
  border: rgb(147 197 253) 2px solid;
}
