

.multiSelect {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 5px;

    .selectedItems,.itemsToSelect {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        height: 32vh;
        padding: 10px;
        border-right: 1px solid #ffffff;
        padding: 5%;

        .items{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            
        }

        .itemToSelect {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 1vh;
            padding-bottom: 1vh;
            height: 50px;

            border-radius: 5px;
            margin: 5px;

            .item-image {
                width: 50px;
                height: 50px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                overflow: hidden;
                padding: 1vh;
                transform: translateX(0.2vh);
                background-color: #f5f5f5;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .item-name {
                z-index: 2;
                padding: 1vh;
                width: calc(100% - 60px);
                height: 50px;
                padding: 10px;
                border-left: 1px solid #000;
                border-radius: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f5f5f5de;
            }

            &:hover {
                .item-name {
                    background-color: #f5f5f5;
                }
                .item-image {
                    background-color: #f5f5f5;
                }
                cursor: pointer;
            }
        }
    }

    .itemsToSelect{
        border-right: none;
    }

    .scroll-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .scroll-prev,.scroll-next {
            width: 100%;
            padding: 10px;
            border: 1px solid #000;
            border-radius: 5px;
            margin: 5px;
            height: 4vh;
            font-size: 1.5vh;
            cursor: pointer;
            text-align: center;
            &:hover {
                background-color: #000;
                color: #fff;
            }
            &:active {
                background-color: #000;
                color: #fff;
            }
            &:disabled {
                background-color: #ccc;
                color: #000;
                cursor: not-allowed;
            }

        }
    }
}
