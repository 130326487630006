$background-color: #1F2633;
$text-color: white;
$card-bg-color: #1d212b;
$card-border-color: #374152;
$accent-color: #ff647c;
$border-radius: 8px;

.product-list {
  background-color: $background-color;
  color: $text-color;
  padding: 20px;
  border-radius: $border-radius;

  &-title {
    margin: 0;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .sort-dropdown {
    padding: 8px;
    background-color: $card-border-color;
    color: $text-color;
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .dropdown-icon{
        margin-right: 10px;
        div{
            background-color: white;
            height: 1px;
            margin: 3px 0;
        }
        div:nth-child(1){
            width: 15px;
        }
        div:nth-child(2){
            width: 10px;
        }
        div:nth-child(3){
            width: 5px;
        }
    }

    .arrow-down{
        //create a slim arrow down with the 2 divs
        
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        svg{
        }
    }
  }
}



//CARD
.product-card {
  background-color: $card-bg-color;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  border: 2px solid $card-border-color;

    .product-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .product-name{
            font-size: 18px;
            margin: 0;
            color: $accent-color;
            font-weight: bold;
          }

        .right-arrow{
            //rotate but keep same z index

        }

    }

    .divider{
        width: 100%;
        height: 1px;
        background-color: $card-border-color;
        margin: 10px 0;
    }


  .product-details {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-param{
        margin-bottom: 3px;
        display: flex;
        flex-direction: column;
    }

    .left-side{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 45%;

        .product-image{

            span{
                font-weight: 50;
            }

            img{
                width: 80%;
                height: 80%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    .right-side{
        display: flex;
        flex-direction: column;
        gap: 1px;
        width: 48%;

        .param-title{
            font-weight: lighter;
            font-size: 0.8rem;
        }
        .param-value{
            font-weight: bold;
            font-size: 0.8rem;
        }
    }
  }
}
