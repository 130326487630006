.shipping-details {
    position: relative;
    color: #ffffff;
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    h2 {
      text-align: center;
      color: #ffffff;
      margin: 0;
      position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      color: #ffffff;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }
    
    .back-button {
      position: absolute;
      top: 10px;
      left: 10px;
      background: none;
      color: #ffffff;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }
  
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-left: 6vw;
      padding-right: 6vw;
      gap: 9vw;
      max-height: 80%;
  
      .info-section,
      .purchases-section {
        width: 48%;
        overflow-y: auto;
        padding: 1vw;
        //shadow
        box-shadow: 10px 14px 20px rgba(0, 0, 0, 0.822);
        border: white 2px solid;
      }
  
      h3 {
        margin: 10px 0;
        font-size: 14px;
        color: #b0b0b0;
      }
  
      p {
        font-size: 14px;
        line-height: 1.6;
        display: flex;
        justify-content: space-between;
  
        span {
          font-weight: bold;
        }
      }
  
      .status-indicator {
        
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #4caf50;
        border-radius: 50%;
        margin-left: 5px;
      }
  
      .purchases {
        display: flex;
        flex-direction: column;
        gap: 10px;
  
        .purchase {
          padding: 8px;
          background-color: #2a2b3d;
          border: 1px solid #3a3b5c;
          border-radius: 5px;
          color: #ffffff;
          position: relative;
          .remove-button{
            position: absolute;
            top: 5px;
            right: 5px;
            height: 20%;
            cursor: pointer;
            transition: transform 0.2s;
            &:hover{
              transform: scale(1.3);
            }
          }
          .purchase-info{
            .purchase-title{
              cursor: default;
              display: block;
              font-size: 1.1rem;
              font-weight: bold;
              width: 100%; /* Set the width you want the text to occupy */
              white-space: nowrap; /* Prevent text from wrapping to a new line */
              overflow: hidden; /* Hide any overflowed text */
              text-overflow: ellipsis; /* Add ellipses (...) at the end if text overflows */
            }

          }
        }
        .add-item-button-container{
          display: flex;
          justify-content: center;
          .add-item-button{
            width: 100%;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .add-item-button {
          padding: 8px;
          background-color: #6c6cff;
          border: none;
          border-radius: 5px;
          color: #ffffff;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.2s;
  
          &:hover {
            background-color: #4f4fd1;
          }
        }
      }
    }
  }
  