

.homeSection.mobile{
    width: 100%;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .footer{
        border-top: 3px solid #ffffff59;
        background-color: #00000070;
        flex-grow: 1;
        p{
            color: #a5a5a5;
            font-size: 0.7rem;
            text-align: center;
            padding: 12px 0;
            margin: 0;
        }
    }

    .homeContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .homeLogo{

            width: 100%;
            height: 20vh;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //box shadow (up and down)
            box-shadow: inset 0px 5px 10px 5px #000000;

            img{
                height: 80%;
            }

            .logoText{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                .textItem{
                    font-weight: bold;
                    color: #fff;
                }
                .dot{
                    background-color: #FE3837;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                }
            }
        }
    }

    .functionalityContent{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: flex-start;
        background-color: #00000070;
        padding-top: 5vh;
        padding-bottom: 5vh;
        .functionality{
            padding-top: 2vh;
            padding-bottom: 2vh;
            width: 25%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            //outer shadow
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);

            .iconContainer{
                border: 3px solid #ffffff;
                border-radius: 50%;
                width: 7vh;
                height: 7vh;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    width: 5vh;
                    height: 5vh;
                }   
            }

            .gifContainer{
                display: none;
            }
            
            h2{
                color: #ffffff;
                font-size: 0.8rem;
                margin-top: 1vh;
            }

            .desc{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 12vh;
                
                p{
                    height: auto;
                    color: #ffffff;
                    font-size: 0.7rem;
                    margin-top: 2vh;
                    padding: 0 10px;
                    text-align: center;
                }
            }
            

            
        }

    }


}