/*
        <div className="select-image">
            <h3>Select images</h3>
            <div className="images">
                {images.map((image, index) => (
                    <div key={index} className="image" onClick={() => imageAdd(image)}>
                        <img src={image} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}}/>
                    </div>
                ))}
            </div>
            <div className="selected-images">
                {selectedImages.map((image, index) => (
                    <div key={index} className="image" onClick={() => imageRemove(image)}>
                        <img src={image} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}}/>
                    </div>
                ))}
            </div>
        </div>
        */

.select-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.selected-images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.image{
    cursor: pointer;
}

.image img{
    height: 100px;
    object-fit: contain;
    border-radius: 5px;
}

