.dropdown-item {
  padding: 0.5rem;
  margin: 0.1rem;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: rgb(190, 190, 190);
}
