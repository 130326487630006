.auth-form-container{
    width: 100%;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    form{
        background-color: rgba(0, 0, 0, 0.53);
        width: 30%;
        padding: 5vh;
        border-radius: 20px;
        border: 3px solid #ffffff98;
        //inner shadow
        box-shadow: 0 0 10px 5px #00000098;

        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
    label{
        font-size: 2vh;
        color: white;
    }

        input{
            width: 40%;
            height: 2vh;
            margin: 1vh 0;
            padding: 0.5vh;
            border-radius: 0.5vh;
            border: 3px solid #ffffff;
        }

        .imageInput{
            color: white;
            width: 60%;
            display: flex;
            height: auto;
        }

        button{
            width: 40%;
            height: 3vh;
            margin: 1vh 0;
            border-radius: 0.5vh;
            border: 1px solid #ffffff;
            background-color: #ffffff;
            color: rgb(0, 0, 0);
            cursor: pointer;
            transition: all 0.2s;
            font-size: 2vh;
            margin-top: 4vh;
            &:hover{
                background-color: #b8b8b8;
            }
        }


        
        .dropdown{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;    
            .dropdown-btn{
                width: 40%;
                height: 3vh;
                margin: 1vh 0;
                padding: 0.5vh;
                border-radius: 0.5vh;
                border: 3px solid #ffffff;
                background-color: #ffffff;
                color: rgb(0, 0, 0);
                cursor: pointer;
                transition: all 0.2s;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover{
                    background-color: #b8b8b8;
                }
            }
        }
    }
}