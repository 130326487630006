
$primary-color: #2e2e2e4f;


.stockSection{
  height: fit-content;
}

.stockContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;

  .stockContainer{
    height: fit-content;
    .table-container{
      max-height: max-content;
      margin-bottom: 8vh;
    }
  }

  .addStock{
    background-color: hsl(0, 0%, 100%);
    border-radius: 10px;
    width: 10vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
    cursor: pointer;
    transition: all 0.2s;
    &:hover{
      background-color: #cccccc;
    }
  }
}

.purchaseContent{
  display: flex;
  flex-direction: column;
  align-items: center;

  .addPurchase{
    background-color: hsl(0, 0%, 100%);
    border-radius: 10px;
    width: 10vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
    cursor: pointer;
    transition: all 0.2s;
    &:hover{
      background-color: #cccccc;
    }
  }
}





table {
    font-family: 'Arial';
    margin: 25px auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.10),
       0px 10px 20px rgba(0,0,0,0.05),
       0px 20px 20px rgba(0,0,0,0.05),
       0px 30px 20px rgba(0,0,0,0.05);

    .typeTh{
        padding: 0;
        .masterTh{
            
        }
        .subTh{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            div{
                position: relative;
                top:2px;
                width: 50%;
            }
        }
    }


    .typeTd{
      padding: 12px 0;
      
        min-width: 12rem;
        display: flex;
        height: 50px;
        flex-direction: row;
        
        div {
            text-align: center;
            width: 50%;
            text-align: center;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;

        }
        div.left-category{
            border-left: none;
        }
        div.right-type{
            border-right: none;
        }
    }

      td.image{
        padding: 0;

        
        img{
          border-radius: 20px;
          width: 6vh;
          height: 6vh;
        }
      }
    
    tr {
      cursor: default;
       &:hover {
        
        td {
          color: #555;
          background: #aeaeae;
          a{
            color: #555;
            &:hover{
              color: $primary-color;
            }
          }
        }

        th{
          background: $primary-color;
        }
      }
      border: 1px solid #eee;


    }
    th, td {
      color: #ffffff;
      border: 1px solid #eee;
      padding: 12px 35px;
      border-collapse: collapse;
      text-align: center;
      a{
        text-decoration: none;
        color: #ffffff;
        }
      
    }
    th {
      background: $primary-color;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      &.last {
        border-right: none;
      }
    }
  }