.homeSection.desktop{
    width: 100%;
    .homeContent{
        margin-top: 5vh;
        width: 100%;
        .homeLogo{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 10vh;
            }
            padding: 4vh;
            margin-bottom: 5vh;
            //shadow cool
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        }
        .globalProfit{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .profit{
                background-color: rgb(94, 94, 94);
                height: 10vh;
                width: 40vh;
                border-radius: 20px;
                border: 3px solid black;
                //inner shadow
                box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);


                h3{
                    color: #ffffff;
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
                p{
                    color: #ffffff;
                    font-size: 0.9rem;
                    margin: 0;
                    padding: 0;
                }
                .profitContainer{
                    display: flex;
                    align-items: center;
                    height: 80%;
                    .profitIcon{
                        height: 80%;
                        img{
                            height: 100%;
                        }
                    }
                    .profitInfo{
                        
                    }
                }
            }
        }
        .functionalityContent{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            .functionality{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 30%;
                .iconContainer{
                    border: 3px solid #ffffff;
                    border-radius: 50%;
                    width: 10vh;
                    height: 10vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 7vh;
                        height: 7vh;
                        //color mask white
                        filter: invert(100%);
                    }
                }
                h2{
                    color: #ffffff;
                    font-size: 1.5rem;
                    margin-top: 1vh;
                }
                p{
                    color: #ffffff;
                    font-size: 1rem;
                    margin-top: 0.5vh;
                }
                .gifContainer{
                    width: 30vh;
                    height: 20vh;
                    display: flex;
                    justify-content: center;
                    margin-top: 1rem;
                    border-radius: 20px;
                    overflow: hidden;
                    border: 3px solid #ffffff;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    } 
}