//define colors
$primary-header: #00000070;
$white: #fff;

header.mobile {
    position: relative;

    .mobile-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $primary-header;
        //shadow
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

        width: 100%;
        height: 7vh;
        .logo {
            padding-left: 3%;
            height: 5vh;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 80%;
            }
        }

        .menu-icon{
            padding-right: 3%;
            height: 5vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 2;
            transition: 0.5s;
            
            div{
                width: 30px;
                height: 1px;
                background-color: $white;
                margin: 5px;
                border-radius: 20px;
                transition: 0.5s;
            }
        }

        //when active first child and last transform to x
        .menu-icon.active{
            div:first-child{
                transform: rotate(45deg) translateY(7.5px) translateX(7.5px);
            }
            div:nth-child(2){
                opacity: 0;
            }
            div:last-child{
                transform: rotate(-45deg) translateY(-7.5px) translateX(7.5px);
            }
            position: fixed;
            top: 10px;
            right: 0;
            z-index: 2;

        }

        
        .hidden{
            transform: translateX(100%);
        }
        //HIDDEN MENU
        .hidden-menu{
            position: fixed;
            top: 0;
            max-height: 100vh;
            right: 0;
            transition: 0.5s;
            width: fit-content;
            height: 100vh;
            
            background: #0f0c29;  /* fallback for old browsers */

            .content{
                background-color: $primary-header;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: auto;
                height: 100vh;

                .menu{
                    margin-top: 7vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                    padding: 20px;
                    flex-grow: 1;

                    max-height: 73vh;
                    overflow-y: scroll;
                    width: auto;
                    overflow-x: hidden;

                    .menu-item{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        border: 1px solid white;
                        padding: 10px;
                        border-radius: 10px;

                        span.title{
                            color: #FE3837;
                            font-size: 1rem;
                            text-decoration: none;
                            font-weight: bold;
                        }
                        .divisor{
                            width: 100%;
                            height: 1px;
                            background-color: white;
                        }
                        a{
                            color: $white;
                            font-size: 0.8rem;
                            text-decoration: none;
                        }
                        .item{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            svg{
                                width: 15px;
                                height: 15px;
                                //color white reverse
                                filter: invert(100%);
                            }
                        }
                    }
                }

                .auth-content{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 10vh;
                    gap: 5px;
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                    a{
                        color: $white;
                        font-size: 0.8rem;
                        text-decoration: none;
                        background-color: #FE3837;
                        padding: 5px;
                        border-radius: 20px;
                        width: 100%;
                        text-align: center;
                    }

                    
                }
            }              
        }


    }
}