
.submit-item-folder{
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        label{
            font-size: 2vh;
            color: white;
        }

        input{
            width: 40%;
            height: 2vh;
            margin: 1vh 0;
            padding: 0.5vh;
            border-radius: 0.5vh;
            border: 3px solid #ffffff;
        }

        .imageInput{
            color: white;
            width: 60%;
            display: flex;
            height: auto;
        }

        button{
            width: 40%;
            height: 3vh;
            margin: 1vh 0;
            border-radius: 0.5vh;
            border: 1px solid #ffffff;
            background-color: #ffffff;
            color: rgb(0, 0, 0);
            cursor: pointer;
            transition: all 0.2s;
            font-size: 2vh;
            margin-top: 4vh;
            &:hover{
                background-color: #b8b8b8;
            }
        }


        
        .dropdown{
            color: black;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;    
            .dropdown-btn{
                width: 40%;
                height: 3vh;
                margin: 1vh 0;
                padding: 0.5vh;
                border-radius: 0.5vh;
                border: 3px solid #ffffff;
                background-color: #ffffff;
                color: rgb(0, 0, 0);
                cursor: pointer;
                transition: all 0.2s;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover{
                    background-color: #b8b8b8;
                }
            }

        }
    }

}


.addProductSection, .addPurchaseSection{
    width: 100%;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .addProductContainer, .addPurchaseContainer{
        background-color: rgba(0, 0, 0, 0.53);
        width: 30%;
        padding: 5vh;
        border-radius: 20px;
        border: 3px solid #ffffff98;
        //inner shadow
        box-shadow: 0 0 10px 5px #00000098;

        .addProductHeader, .addPurchaseHeader{
            font-size: 3vh;
            color: white;
            margin-bottom: 1.5vh;
            display: flex;
            justify-content: center;
        }
}
}



.miniAddProduct{
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    .addProductForm{
        width: 79%;
    }



    .addProductContainer{
        padding: 2vh;
        width: 80%;
        height: 90%;
        display:flex;
        flex-direction: row;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0);
        background: rgba(255, 255, 255, 0);
        
        .image-preview{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
                height: 100%;
                border-radius: 2vh;
            }

            
        }


        .vertical-line{
            width: 2px;
            height: 100%;
            background-color: white;
            margin: 0 2vh;
        }

        .addProductHeader{
            font-size: 2vh;
            font-weight: bold;
            margin-bottom: 0vh;
        }

        label{
            font-size: 1.2vh;
            color: white;
        }

        input{
            height: 1vh;
        }

        //input type file
        input[type="file"]{
            font-size: 1.3vh;
            padding: 0.5vh;
            height: 2vh;

            //when hovered with file change
            
        }

        .dropdown{
            .dropdown-btn{
                height: 1vh;
            }
        }

        button{
            margin-top: 0.2vh;
            font-size: 1.2vh;
        }

        button[type="submit"]{
            width: 50%;
            height: 3vh;
            margin: 1vh 0;
            border-radius: 0.5vh;
            border: 1px solid #ffffff;
            background-color: #000000;
            color: rgb(255, 255, 255);
            cursor: pointer;
            transition: all 0.2s;
            font-size: 2vh;
            margin-top: 4vh;
            &:hover{
                background-color: #ffffff;
                color: black;
            }
        }

        

        .extra-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 100%;

            .extra-info-container{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .extra-info-item{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 4vh;
                    margin: 1vh;
                    padding: 0 1vh;
                    border: 1px solid white;
                    border-radius: 0.5vh;

                    span{
                        font-size: 1vh;
                        color: white;
                    }

                    input{
                        width: 40%;
                        height: 1vh;
                        padding: 0.5vh;
                        border-radius: 0.5vh;
                        border: 3px solid #ffffff;
                    }

                    .extra-info-item-label{
                        font-size: 1.5vh;
                        color: white;
                    }

                    .extra-info-item-input{
                        width: 50%;
                        height: 1.5vh;
                        padding: 0.5vh;
                        border-radius: 0.5vh;
                        border: 3px solid #ffffff;
                    }
                }
            }
        }

        .divider{
            width: 50%;
            height: 2px;
            background-color: white;
        }

        .image-picker-options{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 1vh;
            margin-top: 1vh;
            gap: 2vh;

            button{
                margin: 0;
            }

            input{
                margin: 0;
            }
        }
        .images-preview{
            margin-top: 1vh;
        }
    }
}