//define colors
$primary-header: #000000de;
$white: #fff;

header.desktop {
    display: flex;
    justify-content: center;
    position: relative;
    box-shadow: var(#000, 0 0 #0000), var(#000, 0 0 #0000), var(--tw-shadow);
    .content{
        background-color: $primary-header;
        color: #fff;
        padding: 10px 20px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.5rem;
        width: 60%;
        border-radius: 50px;
    
        .logo {
            height: 100%;
            display: flex;
            align-items: center;
            width: 25%;
            img{
                cursor: pointer;
                height: 110%;
            }
        }

        nav{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 50%;
            ul{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;
                height: 100%;
                margin-left: 0;
                padding-left: 0;

                li{
                    width: 33%;
                    position: relative;
                    margin: 0 10px;
                    padding: 5px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                    height: 70%;
                    transition: background-color 0.2s;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        font-size: 1rem;
                    }
                    svg {
                        margin-left: 5px;
                        position: relative;
                        top: 1px;
                        //not focusable
                        pointer-events: none;
                    }
                    &:hover{
                        background-color: hsla(0, 0%, 100%, 0.096);
                    }
                }
            }
        }
        .authContent{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            width: 25%;
            a{
                margin: 0 10px;
                padding: 5px 20px;
                display: flex;
                align-items: center;
                border-radius: 50px;
                height: 70%;
                background-color: #fff;
                color: #333;
                font-size: 1rem;
                cursor: pointer;
                transition: background-color 0.2s, color 0.2s;
                text-decoration: none;
                &:hover{
                    background-color: hsla(0, 0%, 100%, 0.096);
                    color: #fff;
                }
            }
        }
    }

    .low-prio{
        z-index: 1;
        //click does pass through
        pointer-events: none;
    }
    .overlay{
        position: absolute;
        top: 100%;
        width: 60%;
        height: 35vh;
        display: flex;
        justify-content: center;
        overflow: hidden;
        
        pointer-events: none;
        .ov-content{
            position: absolute;
            top: 0%;
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: space-around;

            ul.dropdown{
                
                pointer-events: all;
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 25%;
                height: max-content;
                background-color: $primary-header;
                transition: transform 0.5s;
                padding: 10px 15px;
                border-radius: 10px;
                gap: 5px;
                li{
                    margin: 0;
                    width: 100%;
                    padding: 5px 10px;
                    border-radius: 50px;
                    text-align: center;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    a{
                        text-decoration: none;
                        color: #fff;
                        font-size: 0.8rem;
                    }
                    &:hover{
                        background-color: hsla(0, 0%, 100%, 0.096);
                    }
                    svg{
                        height: 30px;
                        width: 30px;
                        //not focusable
                        pointer-events: none;
                        //reverse color
                        filter: invert(100%);
                        margin-right: 10px;
                    }
                }
        
                &.database{
                }
                &.analitycs{
                }
                &.tools{
                }
                &.hide{
                    transform: translateY(-120%);
                }
            }
        }
    }

    
    
}