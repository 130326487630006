.tableSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    .sectionTitle{
        font-size: 2rem;
        font-weight: 600;
        margin-top: 3rem;
        margin-bottom: 1rem;
        color: white;
    }

    .addButton{
        background-color: hsl(0, 0%, 100%);
        border-radius: 10px;
        width: 10vw;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          background-color: #cccccc;
        }
      }
}

.popupItem{
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.281);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .popupItemContainer{
    position: relative;
    width: 70%;
    height: 70%;
    background-color: #0b0a16;
    border: #cccccc solid 1px;
    border-radius: 10px;
    
    .exitButton{
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #0b0a16;
      z-index: 1;

      color: white;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;
      svg{
        width: 40px;
        height: 40px;
        //reverse color
        filter: invert(1);
        transition: all 0.5s;
      }
      &:hover{
        background-color: #cccccc;
        color: black;
      }
      svg:hover{
        filter: invert(0);
      }
    }

    .backButton{
      position: absolute;
      top: 10px;
      padding: 5px;
      left: 10px;
      background-color: #0b0a16;
      z-index: 1;

      color: white;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;
      svg{
        width: 30px;
        height: 30px;
        //reverse color
        filter: invert(1);
        transition: all 0.5s;
      }
      &:hover{
        background-color: #cccccc;
        color: black;
      }
      svg:hover{
        filter: invert(0);
      }
    }
  }
}