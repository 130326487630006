$primary-color: #2e2e2e4f;

.table-container{
  max-height: max-content;
  margin-bottom: 8vh;
  //scrollbar
  margin-top: 2rem;
table {
    font-family: 'Arial';
    margin: 25px auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.10),
       0px 10px 20px rgba(0,0,0,0.05),
       0px 20px 20px rgba(0,0,0,0.05),
       0px 30px 20px rgba(0,0,0,0.05);




    tbody {
      .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.4rem;
        button{
          padding: 0.5rem 1rem;
          margin: 0 0.5rem;
          border: none;
          background: $primary-color;
          color: #fff;
          cursor: pointer;
          border: 2px solid #eee;
          &:disabled{
            background: #5f5f5f7e;
            color: #fff;
            cursor: not-allowed;
            &:hover{
              background: #000000b0;
            }
          }
          &:hover{
            background: #333;
          }
        }
        select{
          padding: 0.5rem 1rem;
          margin: 0 0.5rem;
          border: none;
          background: $primary-color;
          color: #fff;
          cursor: pointer;
          border: 2px solid #eee;
          &:hover{
            background: #333;
          }
        }
      }

    }

    .typeTh{
        padding: 0;
        .masterTh{
            
        }
        .subTh{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            div{
                position: relative;
                top:2px;
                width: 50%;
            }
        }
    }


    .typeTd{
      padding: 12px 0;
      
        min-width: 12rem;
        display: flex;
        height: 50px;
        flex-direction: row;
        
        div {
            text-align: center;
            width: 50%;
            text-align: center;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;

        }
        div.left-category{
            border-left: none;
        }
        div.right-type{
            border-right: none;
        }
    }
      
    td:has(img) {
          padding: 0;
      }
      td{
        padding: 0;
        
        img.image{
          border-radius: 20px;
          width: 6vh;
          height: 6vh;
          object-fit: contain;
        }
      }

    //alternate row color
    tr:nth-child(odd) {
      background-color: #00000038;
    }
    tr:nth-child(even) {
      background-color: #ffffff23;
    }

    
    tr {
      cursor: pointer;
       &:hover {
        
        td {
          color: #555;
          background: #aeaeae;
          a{
            color: #555;
            &:hover{
              color: $primary-color;
            }
          }
        }

        th{
          cursor: default;
          background: $primary-color;
        }
      }
      border: 1px solid #eee;


    }
    th, td {
      color: #ffffff;
      border: 1px solid #eee;
      padding: 12px 35px;
      border-collapse: collapse;
      text-align: center;

      //if is too long, hide the text and show it on hover
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 10rem;
      
      

      a{
        text-decoration: none;
        color: #ffffff;
        }
      
    }
    th {
      background: $primary-color;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      &.last {
        border-right: none;
      }
    }
  }
}


.green{
  color: #00ac00;
}

.red{
  color: #ff2424;
}