.purchase-details {
    position: relative;
    color: #ffffff;
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    
    .green{
      color: #4caf50;
    }

    .red{
      color: #f44336;
    }
  
    h2 {
      text-align: center;
      color: #ffffff;
      margin: 0;
      position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      color: #ffffff;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }
  
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-left: 6vw;
      padding-right: 6vw;
      gap: 9vw;
      max-height: 80%;

  
      .info-section,
      .products-section {
        width: 48%;
        overflow-y: auto;
        padding: 1vw;
        //shadow
        box-shadow: 10px 14px 20px rgba(0, 0, 0, 0.822);
        border: white 2px solid;
      }

      .info-section{
        /*
        <h3>IMAGES</h3>
          <div className="images">
            {
              <img src={purchase.images[imagesIndex]['image']} alt={purchase.name} className="main-image" />
            }
            <div className="scroll-left" onClick={() => setImagesIndex(imagesIndex === 0 ? purchase.images.length - 1 : imagesIndex - 1)}>&lt;</div>
            <div className="scroll-right" onClick={() => setImagesIndex(imagesIndex === purchase.images.length - 1 ? 0 : imagesIndex + 1)}>&gt;</div>
            <div className="images-indicators">
              {
                purchase.images.map((image, index) => <div key={index} className={imagesIndex === index ? "indicator active" : "indicator"}></div>)
              }
            </div>
          </div>
          */
        h3 {
          margin: 10px 0;
          font-size: 14px;
          color: #b0b0b0;
        }

        .images {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          position: relative;
          .main-image {
            width: 100%;
            max-height: 20vh;
            object-fit: contain;
          }
          .scroll-left,
          .scroll-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #000000b0;
            color: #ffffff;
            font-size: 1.5rem;
            padding: 0.5rem;
            cursor: pointer;
            //not selectable
            user-select: none;

            &:hover {
              background-color: #000000d0;
            }
          }
          .scroll-left {
            left: 0;
          }
          .scroll-right {
            right: 0;
          }
          .images-indicators {
            display: flex;
            gap: 5px;
            .indicator {
              width: 10px;
              height: 10px;
              background-color: #ffffff;
              border-radius: 50%;
            }
            .active {
              background-color: #6c6cff;
            }
          }
        }
      }
  
      h3 {
        margin: 10px 0;
        font-size: 14px;
        color: #b0b0b0;
      }
  
      p {
        font-size: 14px;
        line-height: 1.6;
        display: flex;
        justify-content: space-between;
        width: 100%;
  
        span.title {
          font-weight: bold;
          margin-right: 10%;
          width: 40%;
        }
        .value{
            white-space: nowrap; /* Prevent text from wrapping to a new line */
            overflow: hidden; /* Hide any overflowed text */
            text-overflow: ellipsis; /* Add ellipses (...) at the end if text overflows */
        }

        .shipmentId{
          cursor: pointer;
          color: #6c6cff;
          &:hover{
            text-decoration: underline;
          }
        }

        a{
          color: #6c6cff;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
      }
  
      .status-indicator {
        
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #4caf50;
        border-radius: 50%;
        margin-left: 5px;
      }
  
      .products {
        display: flex;
        flex-direction: column;
        gap: 10px;
  
        .product {
          padding: 8px;
          background-color: #2a2b3d;
          border: 1px solid #3a3b5c;
          border-radius: 5px;
          color: #ffffff;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .product-image{
            width: 5vh;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 5px;
            }
          }

          .remove-button{
            position: absolute;
            top: 5px;
            right: 5px;
            height: 1.6vh;
            cursor: pointer;
            transition: transform 0.2s;
            &:hover{
              transform: scale(1.3);
            }
          }
          .product-info{
            .product-title{
              cursor: default;
              display: block;
              font-size: 1.1rem;
              font-weight: bold;
              width: 100%; /* Set the width you want the text to occupy */
              white-space: nowrap; /* Prevent text from wrapping to a new line */
              overflow: hidden; /* Hide any overflowed text */
              text-overflow: ellipsis; /* Add ellipses (...) at the end if text overflows */
            }

          }
        }
        .add-item-button-container{
          display: flex;
          justify-content: center;
          .add-item-button{
            width: 100%;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .add-item-button {
          padding: 8px;
          background-color: #6c6cff;
          border: none;
          border-radius: 5px;
          color: #ffffff;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.2s;
  
          &:hover {
            background-color: #4f4fd1;
          }
        }
      }
    }
  }
  